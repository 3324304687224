<template>
	<div class="w-full">
		<div v-if="loading">
			<div class="center text-center p-4">
				<span class="fe fe-spinner">Loading..</span>
			</div>
		</div>
		<template v-else>
			<div v-if="wallets.length" class="flex w-full items-start gap-6 pb-6">
				<div class="w-full flex flex-col gap-[20px] flex-grow lg:w-[calc(100%-380px)]">
					<div class="p-[16px] bg-[#EBEBFF] border !border-[#EBEBFF] rounded-xl flex flex-col gap-[16px]">
						<div class="flex items-center justify-between">
							<div class="flex items-center gap-4">
								<p class="text-sm text-[#101828] m-0">Wallet balance</p>
								<img class="cursor-pointer" @click="showBalance = !showBalance"
									src="@/assets/img/icons/wallet/eye_closed.svg" alt="">
							</div>
							<select v-model="walletIndex"
								class="w-fit bg-transparent border border-[black] rounded text-[black] font-medium px-2">
								<option :value="index" v-for="(n, index) in wallets" :key="n.wallet.id">
									{{ n.wallet.currency }} wallet
								</option>
							</select>
						</div>
						<h3 class="text-[#000005] m-0 text-2xl font-bold mt-[10px]">
							{{ showBalance ? currentWallet?.wallet.available_balance_formatted : `****` }}
						</h3>

						<button @click="useModal().openModal(fund)"
							class=" bg-black border-0 py-2 flex items-center justify-center w-fit gap-2 rounded-lg text-white !px-6 disabled:cursor-not-allowed">
							<img src="@/assets/img/icons/wallet/fund_arrow_up.svg" alt="">
							<span>Fund wallet</span>
						</button>

					</div>
					<div class="lg:hidden grid grid-cols-1 sm:grid-cols-2 gap-4">
						<walletBankDetails  @activation="activateWallet" :obj="currentWallet.wallet.nuban_addresses" />
						<walletOverdrawSettings :walletItem="currentWallet.wallet" @corporateWalletUpdated="updateCurrentWallet" />
					</div>
					
					<div class="flex flex-col gap-4">
						<p class="text-base text-[#000005] font-bold">Wallet history</p>

						<walletTable :walletId="currentWallet.wallet.id" />
					</div>
				</div>
				<div class="hidden lg:flex flex-col gap-4 shrink-0">
					<walletBankDetails @activation="activateWallet" :obj="currentWallet.wallet.nuban_addresses" />
					<walletOverdrawSettings :walletItem="currentWallet.wallet" @corporateWalletUpdated="updateCurrentWallet" />
				</div>

			</div>
			<p v-else>No wallet available </p>
		</template>
	</div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import { useWallets } from '@/composables/backend/wallet'
import { useModal } from '@/composables/core/modal'
import walletTable from '@/views/Company/components/walletTable.vue'
import activateWalletVue from '@/views/Company/modals/activateWallet.vue'
import fund from '@/views/Company/modals/fund.vue'
import walletBankDetails from '@/views/Company/components/walletBankDetails.vue'
import walletOverdrawSettings from '@/views/Company/components/walletOverdrawSettings'

const props = defineProps(['companyId'])
const showBalance = ref(false)
const { loading, fetchWallets, wallets, ledgerAccountId, walletIndex, currentWallet } = useWallets()


const activateWallet = () => {
	ledgerAccountId.value = currentWallet.value?.wallet.ledger_account_id
	useModal().openModal(activateWalletVue)
}

const updateCurrentWallet = (data) => {
	fetchWallets(props.companyId)
}

fetchWallets(props.companyId)

</script>

<style lang="scss" scoped>
.btn {
	@apply w-fit px-4 py-3 rounded-md bg-[black] text-sm text-[white] flex items-center justify-center disabled: bg-neutral-300 disabled:cursor-not-allowed
}
</style>
